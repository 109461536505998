.CoursePage {
  position: relative;
  display: block;
  width: 100%;
  min-height: 700px;
  text-align: center;
  margin: 60px auto 0;
  .container {
    margin: 0 auto;
    width: 1350px;
    position: relative;
    top: -30px;
  }

  .course-title {
    display: block;
    font-size: 42px;
    font-weight: 700;
    color: rgba(2, 169, 249, 1);
    -webkit-text-stroke: 0.3px #ffffff;
    text-shadow: 2px 1px 5px rgba(2, 169, 249, 0.8);
  }

  .course-subtitle {
    margin-top: 10px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
  }

  .course-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .course-item {
    position: relative;
    display: block;
    width: 355px;
    background-color: #ffffff;
    min-height: 200px;
    padding-top: 20px;
    margin: 70px auto 0;
    text-align: center;
    border-radius: 30px;
    padding-bottom: 100px;
  }
  .course-item-image{
    img{
      max-width: 200px;
      height: 100px;
    }
  }

  .course-item-title {
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 20px;
    width: 300px;
    word-wrap: break-word;
    word-break: break-word;
    text-align: center;
    margin: 30px auto 0;

  }

  .course-item-subtitle {
    width: 300px;
    margin: 40px auto 0;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 15px;
    word-wrap: break-word;
    word-break: break-word;
  }

  .course-item-next-image {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}