.MenuView {
  position: absolute;
  margin: 10px auto 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  transition: background-color 0.5s ease-in-out, position 0.3s ease-in-out;
  padding: 5px 25px 5px;
  border-radius: 20px;

  .mobile-navbar {
    display: none;
  }

  &.scrolled {
    position: fixed;
    top: 0;
    background-color: rgba(9, 11, 12, 0.5);
    border-radius: 20px;

  }

  .link-wrapper {
    display: flex;
    align-items: center;
    width: 850px;
    justify-content: space-between;
    color: #fff;
    font-size: 16px;
    margin: 0 auto;

    .page__link {
      display: block;
      margin-top: 4px;
      cursor: pointer;
      color: #ffffff;
      text-decoration: none;
      text-transform: capitalize;
    }

    .on-focus {
      font-weight: 700;
      font-size: 20px;
      color: rgba(2, 169, 249, 1);
      -webkit-text-stroke: 0.4px #ffffff;
      text-shadow: 0 0 5px rgba(2, 169, 249, 0.5);
    }
  }

  .logo {
    width: 130px;
    color: rgba(2, 169, 249, 1);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-right: 20px;

    img {
      display: block;
      width: 80px;
      height: 80px;
      filter: drop-shadow(0 0 5px #fff);
    }

    font-size: 24px;
    font-weight: 600;
    text-align: center;
    align-items: center;

    .logo-text {
      margin-left: 10px;
      text-align: left;
      font-weight: 700;
      color: rgba(2, 169, 249, 1);
      filter: drop-shadow(0 0 5px #fff);

      span {
        color: #ffffff;
        text-shadow: 0 0 5px rgba(2, 169, 249, 1);
      }
    }
  }

  .registration {
    background-color: rgba(2, 169, 249, 1);
    padding: 5px 20px 5px 20px;
    border-radius: 10px;
    color: #ffffff;
    font-size: 18px;
    border: 1px solid #ffffff; /* White border */
    box-shadow: 0 0 20px rgba(2, 169, 249, 0.8), 0 0 30px rgba(2, 169, 249, 0.6); /* Flashier blue shadow */
    cursor: pointer;
  }

}

@media (min-width: 768px) and (max-width: 991.98px) {
  .MenuView {
    .link-wrapper {
      width: 700px;
    }

    .logo {
      width: 90px;
      font-size: 18px;
      margin-right: 30px;

      img {
        display: block;
        width: 55px;
        height: 55px;
      }
    }

    .link-wrapper {
      font-size: 14px;
    }

    .registration {
      font-size: 16px;
    }
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .MenuView {
    .link-wrapper {
      width: 575px;
    }

    .logo {
      width: 90px;
      font-size: 18px;
      margin-right: 30px;

      img {
        display: block;
        width: 55px;
        height: 55px;
      }
    }

    .link-wrapper {
      font-size: 12px;
    }

    .registration {
      font-size: 14px;
      padding: 5px 10px 5px 10px;
    }
  }
}

.mobile-navbar {
  display: none;
}

@media (max-width: 575.98px) {
  .MenuView {
    display: none;
  }
  .registration {
    display: block;
    width: 100px;
    height: 20px;
    text-decoration: none;
    background-color: rgb(39, 51, 58);
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    color: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0 0 20px rgba(2, 169, 249, 0.8), 0 0 30px rgba(2, 169, 249, 0.6); /* Flashier blue shadow */
    cursor: pointer;
    margin-left: -30px;
    text-align: center;
    font-size: 13px;


  }
  .mobile-navbar {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 40vh;
    margin-bottom: 50px;
  }

  .mobile-links {
    height: 100vh;
    width: 60%;
    padding-top: 20px;
    text-align: center;
    align-items: center;
    padding-left: 100px;
    background-color: rgba(9, 11, 12, 0.5);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;


  }
  .mobile-wrapper {
    position: absolute;
    height: 70vh;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    color: #ffffff;
    text-transform: capitalize;
    top: 50%;
    transform: translateY(-50%);
  }
  .page__mobile-link {
    font-size: 22px;
    margin-left: -60px;
  }

  .burger {
    position: fixed;
    display: block;
    width: 30px;
    height: 26px;
    cursor: pointer;
    top: 30px;
    left: 20px;
    border-radius: 100%;
    background-color: white;
    display: flex;
    margin: 0 auto;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 2px;
    padding: 4px 2px 4px 2px;

    div {
      width: 24px;
      height: 3px;
      background-color: rgba(2, 169, 249, 1);
      margin: 0 auto;
      border-radius: 10px;
    }
  }

}