.App {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  background: rgba(20, 81, 110, 255);
  overflow: hidden;
  .container {
    margin: 0 auto;
    width: 1350px;
  }
}
.spinner{
  width: 100%;
  height: 120px;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  z-index: 100;
  img{
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .App {
    .container {
      margin: 0 auto;
      width: 1199px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .App {
    .container {
      margin: 0 auto;
      width: 990px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .App {
    .container {
      margin: 0 auto;
      width: 730px;
    }
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .App {
    .container {
      margin: 0 auto;
      width: 570px;
    }
  }
}

@media (max-width: 575.98px) {
  .App {
    .container {
      margin: 0 auto;
      width: 290px;
    }
  }
}