.NewsPage {
  min-height: 300px;
  background-color: white;
  margin: 0 auto;
  text-align: center;
  padding: 30px 0 50px;

  .container {
    max-width: 1400px;
    margin: 0 auto;
  }

  .news-title {
    display: block;
    font-size: 42px;
    font-weight: 700;
    color: rgba(2, 169, 249, 1);
    -webkit-text-stroke: 0.3px #ffffff;
    text-shadow: 2px 1px 5px rgba(2, 169, 249, 0.8);
    text-transform: capitalize;
  }

  .news-subtitle {
    margin-top: 10px;
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
  }

  .about-line {
    width: 100%;
    height: 1px;
    background-color: #ccc;
    margin: 20px 0;
  }

  .video-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 20px; /* spacing between videos */
    justify-content: space-around;
    margin-top: 20px;
    text-align: center;
    align-items: center;
  }

  @media (max-width: 1200px) {
    .video-wrapper {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller screens */
    }
  }

  @media (max-width: 768px) {
    .video-wrapper {
      grid-template-columns: 1fr; /* 1 column for mobile */
    }
  }
}
