.Map {
  display: block;
  height: 400px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 200px;
  position: relative;
  text-align: center;

  iframe {
    position: relative;
    top: 0;
    width: 830px;
    height: 100%;
    border-radius: 30px;
    margin-bottom: 50px;
    margin-top: 60px;
  }

  .about-title {
    margin: 0 auto;
    display: block;
    font-size: 42px;
    font-weight: 700;
    color: rgba(2, 169, 249, 1);
    -webkit-text-stroke: 0.3px #ffffff;
    text-shadow: 2px 1px 5px rgba(2, 169, 249, 0.8);
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .Map {

    iframe {
      width: 760px;
      height: 100%;
    }
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .Map {

    iframe {
      width: 570px;
      height: 100%;
    }
  }
}

@media (max-width: 575.98px) {
  .Map {

    iframe {
      width: 330px;
      height: 100%;
    }
  }
}