.video-player{
  margin: 30px auto 0;
  width: 300px;
  video{
    width: 100%;
    border-radius: 20px;
    border:  rgba(27, 116, 159, 0.8) 3px solid;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    cursor: pointer;
  }
  .video-title{
    margin-top: 15px;
    font-size: 14px;

  }
}