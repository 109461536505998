.Footer {
  position: relative;
  display: block;
  width: 100%;
  min-height: 100px;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 30px;
  background: url("../../assets/img/it_park.png" ) no-repeat center center/cover;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  margin: 0 auto ;

  .wrapper {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin: 0 auto;
    flex-wrap: wrap;
  }

  .footer-item {

    width: 320px;
    text-align: left;
    iframe{
      margin-top: 10px;
      width: 100%;
      height: 180px;
      border-radius: 10px;
    }
  }

  .footer-item-title {
    margin-top: 20px;
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 700;
  }

  .footer-item-subtitle {
    display: block;
    color: rgb(239, 239, 239);
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
  }

  .copy-right {
    margin-top: 20px;

    display: inline-block;
  }

}