.PartnersPage {
  position: relative;
  display: block;
  width: 100%;
  min-height: 500px;
  text-align: center;
  margin: 100px auto 0;
  padding-bottom: 150px;
  .container {
    width: 1350px;
    position: relative;
    margin: 0 auto;

  }

  .global-title {
    display: block;
    font-size: 42px;
    font-weight: 700;
    color: rgba(2, 169, 249, 1);
    -webkit-text-stroke: 0.3px #ffffff;
    text-shadow: 2px 1px 5px rgba(2, 169, 249, 0.8);
    text-transform: capitalize;

  }

  .global-subtitle {
    margin-top: 10px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
  }
  .wrapper{
    display: flex;
    justify-content: space-evenly;
    margin: 120px auto 50px;
    width: 80%;
    overflow: hidden;

    img{
      display: block;
      height: 220px;
      margin: 0 50px;
      border-radius: 15px;
    }
  }
}