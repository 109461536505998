.HomePage {
  position: relative;
  display: block;
  height: 780px;
  width: 100%;
  padding-top: 120px;
  background: url(../../assets/img/landing_post_2.png) no-repeat center center/cover;
  padding-bottom: 80px;
  .container {
    margin: 0 auto;
    width: 1350px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

  }

  .wrapper {
    position: relative;
    width: 100%;
    min-height: 100px;
    display: flex;
    justify-content: right;
    margin: 50px auto 0 35px;
  }

  .wrapper-item {
    position: relative;
    width: 408px;
    z-index: 2;
  }

  .home-title {
    display: block;
    font-size: 38px;
    font-weight: 700;
    color: rgba(2, 169, 249, 1);
    -webkit-text-stroke: 0.5px #ffffff;
    text-shadow: 0 0 5px rgba(2, 169, 249, 0.5);
    word-wrap: break-word;
    word-break: break-word;
  }

  .home-subtitle {
    color: rgba(255, 255, 255, 1);
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    word-wrap: break-word;
    word-break: break-word;
  }

  .home-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 330px;

    img {
      width: 25px;
      margin-right: 5px;
      text-align: center;
      vertical-align: center;
    }
  }

  .home-call {
    background-color: rgba(2, 169, 249, 1);
    padding: 10px 20px 8px 15px;
    border-radius: 10px;
    color: #ffffff;
    font-size: 18px;
    border: 1px solid #ffffff; /* White border */
    box-shadow: 0 0 20px rgba(2, 169, 249, 0.8), 0 0 30px rgba(2, 169, 249, 0.6); /* Flashier blue shadow */
    cursor: pointer;
    text-decoration: none;
    img {
      width: 20px;
      margin-right: 10px;
    }

  }
}

@media (min-width: 1600px) {
  .HomePage {
    height: 1000px;

    .wrapper {
      margin-left: 120px;
      margin-top: 150px;
    }
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .HomePage {
    height: 810px;
    .wrapper{
      margin-top: 80px;
      margin-left: 10px;
    }
    .wrapper-item{
      width: 380px;
    }
    .home-title{
      font-size: 34px;
    }
    .home-subtitle{
      font-size: 18px;
    }
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .HomePage {
    height: 700px;
    .wrapper{
      margin-left: 0px;
    }
    .wrapper-item{
      width: 340px;
    }
    .home-title{
      font-size: 34px;
    }
    .home-subtitle{
      font-size: 18px;
    }
  }
}
@media (min-width: 1050px) and (max-width: 1199.98px) {
  .HomePage {
    height: 600px;
    .wrapper{
      margin-left: 30px;
    }
    .wrapper-item{
      width: 315px;
    }
    .home-title{
      font-size: 26px;
    }
    .home-subtitle{
      font-size: 16px;
    }
    .home-buttons{
      width: 310px;
    }
    .home-call {
      font-size: 16px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1049px) {
  .HomePage {
    height: 550px;
    .wrapper{
      margin-left: 0px;
    }
    .wrapper-item{
      width: 315px;
    }
    .home-title{
      font-size: 26px;
    }
    .home-subtitle{
      font-size: 16px;
    }
    .home-buttons{
      width: 270px;
    }

    .home-call{
      padding: 5px 15px 3px 10px;
      font-size: 16px;
      img {
        width: 18px;
        margin-right: 5px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .HomePage {
    height: 440px;
    .wrapper{
      position: relative;
      margin-left: 0px;
      margin-top: 20px;
    }
    .wrapper-item{
      width: 280px;
      left: 20px;
    }
    .home-title{
      font-size: 24px;
    }
    .home-subtitle{
      font-size: 14px;
    }

    .home-buttons{
      width: 270px;
    }
    .home-call{
      padding: 5px 15px 3px 10px;
      font-size: 16px;
      img {
        width: 18px;
        margin-right: 5px;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .HomePage {
    height: 440px;
    .wrapper{
      justify-content: center;
      margin: 10px auto 0;
    }
    .wrapper-item{
      width: 415px;
      text-align: center;
    }
    .home-buttons{
      margin: 30px auto 0;
    }
    .home-title{
      font-size: 31px;
      width: 480px;

    }
    .home-subtitle{
      margin: 0 auto;
      width: 360px;
      font-size: 18px;
    }
  }
}
@media (max-width: 575.98px) {
  .HomePage {
    height: 560px;
    .wrapper{
      justify-content: center;
      margin: 0 auto;
    }
    .wrapper-item{
      width: 350px;
      text-align: center;
      margin: 0 auto;
    }
    .home-title{
      font-size: 30px;
      width: 320px;
      text-align: center;
    }
    .home-subtitle{
      margin: 0 auto;
      width: 320px;
      font-size: 16px;
    }
    .home-buttons{
      margin: 30px auto 0;
    }
  }
}