.SocialMedia {
  position: fixed;
  display: flex;
  justify-content: space-evenly;
  height: 220px;
  width: 40px;
  flex-direction: column;
  bottom: 30px;
  left: 20px;
  transition: flex-direction 0.6s ease-in-out, width 0.6s ease-in-out, height 0.4s ease-in-out;

  img {
    width: 40px;
  }

  &.row {
    flex-direction: row;
    width: 280px;
    height: 60px;
  }
}

@media (min-width: 1600px) {
  .SocialMedia {
    &.row {
      flex-direction: column;
      width: 140px;
      height: 150px;
    }
  }
}
