.AboutPage {
  position: relative;
  width: 100%;
  margin: 0 auto ;
  text-align: center;
  min-height: 740px;
  .container {
    margin: 0 auto;
    width: 1350px;
    position: relative;
    top: -130px;
    ;
  }
  .about-title{
    display: block;
    font-size: 42px;
    font-weight: 700;
    color: rgba(2, 169, 249, 1);
    -webkit-text-stroke: 0.3px #ffffff;
    text-shadow: 2px 1px 5px rgba(2, 169, 249, 0.8);
  }
  .about-subtitle{
    margin-top: 10px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
  }
  .about-wrapper{
    position: relative;
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin: 0px auto 0;
    flex-wrap: wrap;
  }
  .about-item{
    width: 323px;
    margin: 60px auto 0;
  }
  .about-item-text{
    font-size: 15px;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    line-height: 26px;
    word-wrap: break-word;
    word-break: break-word;
  }
  .about-image-block{
    display: block;
    background: rgba(2, 169, 249, 1);
    padding: 25px 20px 20px 10px;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;

    img{
      width: 348px;
      height: 482px;
      margin-left: 20px;

    }
  }
  .about-item-title{
    font-size: 20px;
    line-height: 28px;
    text-align: left;
    color: #ffffff;
    font-weight: 600;
    word-wrap: break-word;
    word-break: break-word;
    span{
      font-size: 18px;
      font-weight: 400;
    }
  }
  .about-line-left{
    display: block;
    width: 100px;
    height: 3px;
    background: rgba(2, 169, 249, 1);
    margin: 20px auto 15px 0;
    border-radius: 2px;
  }

}
.about-line{
  display: block;
  width: 115px;
  height: 2px;
  background: rgba(2, 169, 249, 1);
  margin: 20px auto 15px;
  border-radius: 2px;
}
@media (max-width: 575.98px) {
  .AboutPage {
    .about-image-block{
      display: block;
      background: rgba(2, 169, 249, 1);
      padding: 25px 20px 20px 10px;
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px;
      margin-left: -30px;
      width: 280px;
      img{
        width: 300px;
        height: 442px;
        margin-left: 20px;

      }
    }
  }
}