.GlobalPage {
  position: relative;
  display: block;
  width: 100%;
  min-height: 900px;
  margin: 80px auto 0;
  text-align: center;

  .container {
    margin: 0 auto;
    width: 1350px;
    position: relative;
  }

  .global-title {
    display: block;
    font-size: 42px;
    font-weight: 700;
    color: rgba(2, 169, 249, 1);
    -webkit-text-stroke: 0.3px #ffffff;
    text-shadow: 2px 1px 5px rgba(2, 169, 249, 0.8);
    text-transform: capitalize;
  }

  .global-subtitle {
    margin-top: 10px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
  }

  .wrapper {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
    margin: 80px auto 0;
  }

  .line-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 532px;
    height: 130px;
    background-color: #ffffff;
    border-radius: 30px;
    padding: 20px;
    margin-top: 40px;
    flex-wrap: wrap;

  }

  .global-item-title {
    color: black;
    font-weight: 700;
    font-size: 20px;
    text-align: left;
  }

  .global-item-subtitle {
    color: black;
    font-weight: 600;
    font-size: 15px;
    text-align: left;
    line-height: 28px;
  }

  .line-progress-item-1 {
    width: 90%;
    height: 16px;
    background-color: rgba(2, 169, 249, 1);
    border-radius: 10px;
  }

  .line-progress-item-2 {
    position: relative;
    width: 90%;
    height: 16px;
    background-color: rgba(217, 217, 217, 1);
    border-radius: 10px;
  }

  .line-progress-item-blue {
    position: absolute;
    display: block;
    background-color: rgba(2, 169, 249, 1);
    border-radius: 10px;
    width: 70%;
    height: 16px;
    left: 0;
    top: 0;
    z-index: 3;
  }

  .fat-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 320px;
    height: 380px;
    background-color: #ffffff;
    border-radius: 30px;
    padding: 25px;
    margin-top: 20px;
  }

  .fat-item-1, .fat-item-3 {
    position: relative;
    width: 100%;
    height: 16px;
    background-color: rgba(217, 217, 217, 1);
    border-radius: 10px;
  }

  .fat-item-2 {
    position: relative;
    width: 100%;
    height: 16px;
    background-color: rgba(2, 169, 249, 1);
    border-radius: 10px;
    z-index: 10;
  }

  .text {
    position: relative;
    display: block;
    text-align: left;
    margin-left: 20px;
    color: #ffffff;
    font-size: 11px;
    z-index: 10;
  }

  .item-2 {
    height: 230px;
    margin-top: -30px;
  }

  .item-1 {
    height: 150px;
  }

  .fat-item-1 {
    .line {
      position: absolute;
      display: block;
      background-color: rgba(2, 169, 249, 1);
      border-radius: 10px;
      width: 75%;
      height: 16px;
      left: 0;
      top: 0;
      z-index: 5;
    }
  }

  .fat-item-3 {
    .line {
      position: absolute;
      display: block;
      background-color: rgba(2, 169, 249, 1);
      border-radius: 10px;
      width: 85%;
      height: 16px;
      left: 0;
      top: 0;
      z-index: 5;
    }
  }
}

@media (min-width: 1050px) and (max-width: 1199.98px) {
  .GlobalPage {
    .item-2{
      height: 150px;
      margin: 20px;
    }

  }
}
@media  (min-width: 576px)  and (max-width: 1050px) {
  .GlobalPage {
    .item-2{
      height: 150px;
      margin: 20px;

    }
    .line-item{
      width: 320px;
    }
  }

}
@media (max-width: 575.98px) {
  .GlobalPage {
    .item-2{
      height: 200px;
      margin-top: 30px;
    }
    .line-item{
      width: 320px;
    }
  }
}