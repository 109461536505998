.LanguageSwitcher{
  position: fixed;
  right: 50px;
  bottom: 70px;
  width: 40px;
  height: 40px;
  background-color: rgba(2, 169, 249, 1);
  z-index: 15;
  border-radius: 100%;
  cursor: pointer;
  box-shadow: 0 0 20px rgba(2, 169, 249, 0.8), 0 0 30px rgba(2, 169, 249, 0.6); /* Flashier blue shadow */
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  div{
    margin-top: 8px;
  }
}